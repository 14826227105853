import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import SEO from "../components/seo"
import CustomCalendar from "../components/customcalendar"
import EventsSlider from "../components/eventsslider"



class PageTemplate extends Component {

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const allAlerts = this.props.data.allNodeAlert
    
    //2023/01/09 updated vvv
	  //Filter Department events based on today's point in time. In other words, events from previous dates are not shown.
    const allevents = this.props.data.allNodeEvent.edges
    var events = [];
    let today = new Date();
    var moment = require('moment'); // require
    var startDate = moment(today).format("YYYY-MM-DD");
    if(allevents.length>0)
    {
      var events = allevents.filter(item => moment(item.node.field_start_date).format("YYYY-MM-DD")>=startDate);
    }
    //2023/01/09 updated ^^^

    return (
        <Layout >
        <SEO
            title={landingPage.relationships.field_hero.field_title_html.value + ' - Events'}
            metaData={null}
            contentBody={null}
            locationOrigin={this.props.location.origin}
            domen={this.props.location.origin}
        />

          <div className={"site-content events"}>
          {landingPage.relationships.field_hero ? (
            <TopHero landing={landingPage} menu={groupMenu} showNews={landingPage.field_show_news_in_menu} showEvents={landingPage.field_show_events_in_menu} showDocs={landingPage.field_show_forms_docs_in_menu} islanding="0" alerts={allAlerts} />
          ) : (
            null
          )}

          <section className="breadcrumb">
            <div className="container">
              <ul className="uk-breadcrumb breadcrumb-list">
                <li><Link to="/">Home</Link></li>
                {landingPage.relationships.field_parent_agency !== null && landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
                  <li><Link to={landingPage.relationships.field_parent_agency.path.alias}>{landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                ) : (
                  null
                )}
                <li><Link to={landingPage.path.alias}>{landingPage.relationships.field_hero.field_title_html.value}</Link></li>
                <li className="uk-disabled">Events</li>
              </ul>
            </div>
          </section>
          <section>
            <div className="container">
              <div id="events-slider" className="uk-position-relative uk-visible-toggle uk-slider" tabIndex="-1" data-uk-slider >
                {events.length > 0 ? (
                  <EventsSlider events={events} />
                ) : (
                  <p>
                    There are currently no events to display.
                  </p>
                )}
              </div>
              {events.length > 0 ? (
                <CustomCalendar eventsRaw={events} />
              ) : (
                null
              )}

            </div>
          </section>



        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($groupmenu: String, $landing: Int, $groupid: Int) {

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
        ...node__alertEdgeFragment
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }

    allNodeEvent(sort: {fields: field_start_date},filter: {relationships: {group_content__departments_group_node_event: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          field_virtual_event
          body {
            value
          }
          created
          field_end_date
          field_start_date
          field_address_line_1
          field_address_line_2
          path {
            alias
          }
          relationships {
            group_content__departments_group_node_event {
              relationships {
                gid {
                  drupal_internal__id
                  label
                }
              }
            }
          }
        }
      }
    }

    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
